import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import type RestResponse from '@/common/data/RestResponse'
import type { ExternalBeneficiary } from './beneficiaryService'

@injectable()
class BeneficiaryAccountService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(params: ExternalBeneficiaryAccountParameters): Promise<RestResponse<Array<ExternalBeneficiaryAccount>>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${params.workspaceUUID}/enrollment/${params.enrollmentId}/account/${params.accountId}/external-beneficiary-account`
    )
  }

  create(
    params: ExternalBeneficiaryAccountParameters,
    dto: ExternalBeneficiary
  ): Promise<RestResponse<ExternalBeneficiaryAccount>> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${params.workspaceUUID}/enrollment/${params.enrollmentId}/account/${params.accountId}/external-beneficiary-account`,
      dto
    )
  }

  delete(
    params: ExternalBeneficiaryAccountParameters,
    accountExternalBeneficiaryId: string
  ): Promise<RestResponse<ExternalBeneficiaryAccount>> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${params.workspaceUUID}/enrollment/${params.enrollmentId}/account/${params.accountId}/external-beneficiary-account/${accountExternalBeneficiaryId}`
    )
  }
}

export default BeneficiaryAccountService

export type ExternalBeneficiaryAccount = {
  accountExternalBeneficiaryId: string
  externalBeneficiaryId: string
  accountId: number
}

export type ExternalBeneficiaryAccountParameters = {
  workspaceUUID: string
  enrollmentId: number
  accountId: number
}
