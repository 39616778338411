<script lang="ts">
import { defineComponent } from "vue";
import { $inj } from "@/common/decorators/depinject";
import LanguageFactory from "@/common/services/Language/LanguageFactory";
import "@banno/jha-wc/src/icons/jha-icon-plus";

let languageFactory: LanguageFactory;
export default defineComponent({
  name: "bltAddAdditionalButton",
  emits: ["click"],
  data() {
    return {
      uiTextValue: ""
    };
  },
  props: {
    uiText: { type: String, required: false },
    bltDisabled: { type: Boolean, default: false }
  },
  setup() {
    const languageFactory = $inj(LanguageFactory);
    return { languageFactory };
  },
  created() {
    if (this.uiText) {
      this.languageFactory.get(this.uiText).then((response) => {
        this.uiTextValue = response;
      });
    }
  },
  beforeMount() {
    this.onInit(this.$el);
  },
  methods: {
    onInit($el: any) {
      if ($el === null) return;
      $el.addClass("layout-fill layout-padding layout-margin");
      $el.css({
        display: "inline-block",
        "box-sizing": "border-box"
      });
    },
    bltClick(event: MouseEvent) {
      event.preventDefault();
      this.$emit("click");
    }
  }
});
</script>
<template>
  <button class="blt-add-additional-button" :aria-label="uiTextValue" :disabled="bltDisabled" @click="bltClick($event)">
    <div class="d-flex">
      <jha-icon-plus></jha-icon-plus>
      <div class="add-btn-text" v-if="uiText">{{ uiTextValue }}</div>
      <slot v-else></slot>
    </div>
  </button>
</template>
<style scoped>
jha-icon-plus, .add-btn-text {
  color: var(--link-button-text-color);
  fill: var(--link-button-text-color);
}

jha-icon-plus:hover, .add-btn-text:hover{
  color: var(--link-button-text-focused-color);
  fill: var(--link-button-text-focused-color);
}

.blt-add-additional-button:active jha-icon-plus,
.blt-add-additional-button:active .add-btn-text {
  color: var(--link-button-text-focused-color);
  fill: var(--link-button-text-focused-color);
}

.blt-add-additional-button[disabled] jha-icon-plus,
.blt-add-additional-button[disabled] .add-btn-text {
  color: var(--link-button-text-disabled-color);
  fill: var(--link-button-text-disabled-color);
}

.blt-add-additional-button {
  background: var(--jha-button-background) transparent !important;
  border: none;
  color: var(--link-button-text-color);
  cursor: pointer;
  font-family: "roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}
.add-btn-text {
  line-height: 24px;
  padding-right: var(--jh-space-100);
}
.link-icon {
  fill: var(--link-button-text-color);
}
</style>
